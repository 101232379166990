@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+AR:wght@100..400&display=swap");

:root {
  --clr-neutral-900: hsl(210, 100%, 50%);
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-accent-400: hsl(0, 0%, 100%);
}

  .offered-courses-container body {
    /* min-height: 100vh; */
    place-items: center;
    font-family: sans-serif;
    padding-right: 15em;
  }
  .offered-courses-container .card {
    background-size: cover;
    background: transparent;
    border: none;
    padding: 2rem 0 0;
    max-width: 42ch;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms ease;
  }
  
  .offered-courses-container .card:hover,
  .card:focus-within {
    transform: scale(1.05);
  }
  
  .offered-courses-container .card-content {
    --padding: 1.5rem;
    padding: var(--padding);
    background: linear-gradient(
      hsl(0 0% 0% / 0),
      hsl(20 0% 0% / 0.3) 20%,
      hsl(0 0% 0% / 1)
    );
    font-size:20px ;
  font-family: "Dosis", sans-serif !important;
  }
  
  .offered-courses-container .card-title {
    position: relative;
    width: max-content;
    
  }
  
  .offered-courses-container .card:hover .card-title::after,
  .card:focus-within .card-title::after {
    transform: scaleX(1);
  }
  
  .offered-courses-container .card-title::after {
    content: "";
    position: absolute;
    height: 3px;
    width: calc(100% + var(--padding));
    left: calc(var(--padding) * -1);
    bottom: -10px;
    background: var(--clr-accent-400);
    transform-origin: left;
    transition: transform 500ms ease;
  }
  
  @media (hover) {
    .card-content {
      transform: translateY(65%);
      transition: transform 500ms ease;
    }
  
    .card-content > *:not(.card-title) {
      opacity: 0;
      transition: opacity 500ms linear;
    }
  
    .card:hover .card-content > *:not(.card-title),
    .card:focus-within .card-content > *:not(.card-title) {
      opacity: 1;
      transition-delay: 700ms;
    }
  
    .card:hover .card-content,
    .card:focus-within .card-content {
      transform: translateY(0);
      transition-delay: 500ms;
    }
  
    .card:focus-within .card-content {
      transition-duration: 0ms;
    }
  
    .card-title::after {
      transform: scaleX(0);
    }
  }
  
  
  
  .card-body {
    color: rgb(255, 255, 255/0.85);
  }
  
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 2ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 2ms !important;
      scroll-behavior: auto !important;
      transition-delay: 2ms !important;
    }
  }
  
  
