.enroll-form {
  padding: 20px;
  background-color: transparent;
}

.card-border {
  border: none !important;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: transparent;
}
.form-control::placeholder {
  color: #fff !important;
}
.card-border.bg-dark {
  background-color: transparent;
  color: #fff;
}
.form-label {
  font-family: "Dosis", sans-serif;
}

.card-title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
}

.form-control {
  padding: 15px !important; 
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: transparent !important;
  color: #fff !important;
  font-family: "Dosis", sans-serif;
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #fff;
}

button[type="submit"] {
  padding: 7px 15px;
  border-radius: 5px;
  border: none;
  background-color: #006e26;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
}

button[type="submit"]:hover {
  background-color: transparent;
  border: 1px solid white;
}

.form-group {
  margin-bottom: 20px;
}

select.form-control {
  padding: 10px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}

option {
  background-color: transparent !important;
  color: black;
}

@media (max-width: 768px) {
  .enroll-form {
    padding: 20px 0;
  }
}

@media (max-width: 576px) {
  .enroll-form {
    padding: 10px 0;
  }
}
.enroll-form .card-border:hover{
    border: 1px solid gray !important;
}